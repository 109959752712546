import { ListTeaser as ChameleonListTeaser } from '@mediahuis/chameleon-react';
import { ComponentType, FC } from 'react';
import { timeZone } from '@hubcms/brand';

import { ListTeaserProps as ChameleonListTeaserProps } from '../../domain/chameleon/list-teaser-props';

import { ListTeaserPlaceholderProps } from './types';
import styles from './list-teaser-placeholder.module.scss';

// TODO: Chameleon might create this instead
const ListTeaser: FC<ListTeaserPlaceholderProps> = ({ hasDateTime, ...props }) => {
  const Component = ChameleonListTeaser as ComponentType<ChameleonListTeaserProps>;
  const dateTime = new Date(0).toISOString();

  return (
    <Component
      link=""
      title="　"
      dateTime={hasDateTime ? dateTime : undefined}
      titleTagName={`h${props.headerLevel}` as ChameleonListTeaserProps['titleTagName']}
      dateTimeOptions={{ timeZone }}
      className={styles.teaserPlaceholder}
    />
  );
};

export default ListTeaser;
